/*
 * CSS for Sygnal Webflow Util Track library
 */
[wfu-show-logged-in] {
  display: none;
}

[wfu-hide-logged-in] {
  display: none;
}

[wfu-login-button] {
  cursor: pointer;
}